<template>
  <div ref="main" class="m-main-wrap" v-loading="loadList">
    <div ref="topDom">
      <!-- 搜索 start -->
      <div class="flex-l flex-wrap lhx40">
        <el-input
          class="w200 mr10 mb20"
          placeholder="请输入商品名稱/ID"
          clearable
          v-model="filters.gtitle"
          @clear="search"
          @keyup.enter.native="search"
        ></el-input>
        <el-input
          class="w240 mr10 mb20"
          placeholder="请输入用戶名稱/ID/手機號碼"
          clearable
          v-model="filters.utitle"
          @clear="search"
          @keyup.enter.native="search"
        ></el-input>
        <div>
          <el-button class="mb20" type="primary" @click="search"
            >搜尋</el-button
          >
        </div>
      </div>
    </div>
    <!-- 内部侧边栏 -->
    <el-container>
      <el-main>
        <!-- 表格 -->
        <el-table
          :data="tableData.data"
          style="width: 100%"
          :height="tableHeight"
        >
          <el-table-column width="60" label="NO.">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column label="商品ID" width="160">
            <template slot-scope="scope">{{ scope.row.randname }}</template>
          </el-table-column>
          <el-table-column label="商品信息" width="260">
            <a :href="constants.clientUrl+'/goodsDetails?id='+scope.row.pid" target="_blank" slot-scope="scope">
              <div class="flex-l flex-mid">
                <div>
                  <el-image
                    v-if="scope.row.image"
                    style="width: 60px; height: 60px"
                    :src="constants.imgUrl + scope.row.image"
                    fit="contain"
                  ></el-image>
                </div>
                <div class="w200 ml20">
                  <div>{{ scope.row.title }}</div>
                  <div class="flex-bt color-999">
                    <span>{{ scope.row.nid }}</span>
                    <span>HK${{ scope.row.price }}</span>
                  </div>
                </div>
              </div>
            </a>
          </el-table-column>
          <el-table-column label="賣家用戶名稱" width="150">
            <template slot-scope="scope">
              {{ scope.row.nickname }}
            </template>
          </el-table-column>
          <el-table-column width="120" label="賣家用戶ID">
            <a
              :href="
                constants.clientUrl + '/userDetails?userId=' + scope.row.suid
              "
              target="_blank"
              class="el-link el-link--primary is-underline"
              slot-scope="scope"
            >
              {{ scope.row.realname }}
            </a>
          </el-table-column>
          <el-table-column label="提交時間" prop="createBy" width="140">
            <template slot-scope="scope">
              {{ scope.row.create_time | formatTime }}
            </template>
          </el-table-column>
          <el-table-column label="審核結果">
            <!-- "status": 0,//審核結果，0.未審核，1.不予處理，2.封禁商品 -->
            <template slot-scope="scope">
              <el-tag v-if="scope.row.status == 0">未審核</el-tag>
              <el-tag type="warning" v-if="scope.row.status == 1"
                >不予處理</el-tag
              >
              <el-tag type="danger" v-if="scope.row.status == 2"
                >封禁商品</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column label="審核意見" width="100">
            <template slot-scope="scope">
              <el-popover
                v-if="scope.row.opinion"
                placement="top-start"
                title="審核意見"
                width="200"
                trigger="hover"
                :content="scope.row.opinion"
              >
                <div class="c-note" slot="reference">
                  {{ scope.row.opinion }}
                </div>
              </el-popover>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column label="審核狀態">
            <template slot-scope="scope">
              <!-- 0.待審核，1.已審核 -->
              <el-tag v-if="scope.row.state == 0">待審核</el-tag>
              <el-tag type="success" v-if="scope.row.state == 1">已審核</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-link
                v-if="scope.row.state == 0"
                type="primary"
                @click="openCheckDialog(scope.$index)"
                >審核</el-link
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 ｜ 批量移动-->
        <div ref="btmDom" class="flex-r flex-mid pb10 pt10">
          <el-pagination
            background
            hide-on-single-page
            :page-size="tableData.per_page"
            :current-page="tableData.current_page"
            :total="tableData.total"
            @current-change="changePage"
          ></el-pagination>
        </div>
      </el-main>
    </el-container>
    <checkShopVue
      ref="createDialog"
      :item="curItem"
      :show="showDialog"
      @close="showDialog = false"
      @confirm="confirm"
    ></checkShopVue>
  </div>
</template>
<script>
import { calTableHeight } from '@/common/tool'
import checkShopVue from './components/checkShop.vue'
export default {
  components: { checkShopVue },
  data() {
    return {
      loadList: false,
      curItem: {},
      curIndex: -1,
      showDialog: false,
      filters: {
        title: '',
      },
      tableData: {
        data: [],
        current_page: 1,
        per_page: 15,
        total: 0,
      },
      tableHeight: 100, //表格高度
    }
  },
  created() {
    this.getDataList()
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = calTableHeight(this)
    })
  },
  methods: {
    async getDataList(page) {
      this.loadList = true
      let res = await this.api.report.getGoodsReports({
        page,
        ...this.filters,
      })
      if (res && res.code == 0) {
        this.tableData = res.data
      }
      this.loadList = false
    },
    openCheckDialog(index) {
      this.curIndex = index
      this.curItem = this.tableData.data[index]
      this.showDialog = true
    },
    search() {
      if (!isNaN(this.filters.max) && !isNaN(this.filters.min)) {
        if (
          this.filters.max &&
          this.filters.min &&
          this.filters.max < this.filters.min
        ) {
          this.$message({
            message: '最高價格不能小于最低價格',
            type: 'warning',
          })
          return
        }
      }
      this.getDataList()
    },
    changePage(pageNum) {
      this.getDataList(pageNum)
    },
    confirm(data) {
      this.checkData(data)
    },
    async checkData(data) {
      let res = await this.api.report.createGoodsReport(data)
      if (res && res.code == 0) {
        this.getDataList(this.tableData.current_page)
        this.showDialog = false
        this.$message({
          message: '審核成功',
          type: 'success',
        })
      }
      this.$refs['createDialog'].saving = false
    },
  },
}
</script>
<style scoped>
/deep/.el-form-item__label {
  color: #999;
}
/deep/.el-form--inline .el-form-item {
  padding-right: 20px;
}
</style>
